import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  FormGroup,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import qrcode from "qrcode-js";
import { v4 as uuidv4 } from "uuid";

function AbonosTiendasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_ABONOS_TIENDAS = process.env.REACT_APP_URL_ABONOS_TIENDAS;
  const URL_VENTAS_TIENDAS = process.env.REACT_APP_URL_VENTAS_TIENDAS;
  const PASSWORD_CAMBIA_PRECIO = process.env.REACT_APP_PASSWORD_CAMBIA_PRECIO;
  const AREAS_TOMATLAN = process.env.REACT_APP_AREAS_TOMATLAN;
  const AREAS_COCO = process.env.REACT_APP_AREAS_COCO;
  const AREAS_PLAZA = process.env.REACT_APP_AREAS_PLAZA;
  const AREAS_SN_ANTONIO = process.env.REACT_APP_AREAS_SN_ANTONIO;
  const URL_ARTICULOS_VENTAS_TIENDAS =
    process.env.REACT_APP_URL_ARTICULOS_VENTAS_TIENDAS;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_MOVIMIENTOS_BANCOS = process.env.REACT_APP_URL_MOVIMIENTOS_BANCOS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;

  const BANCO_BANAMEX_SHALOMICO = process.env.REACT_APP_BANCO_BANAMEX_SHALOMICO;
  const BANCO_BANAMEX_TANDERA = process.env.REACT_APP_BANCO_BANAMEX_TANDERA;
  const BANCO_BBVA_COCO_STYLE = process.env.REACT_APP_BANCO_BBVA_COCO_STYLE;
  const BANCO_EFECTIVO_TOMATLAN = process.env.REACT_APP_BANCO_EFECTIVO_TOMATLAN;
  const BANCO_EFECTIVO_SN_ANTONIO =
    process.env.REACT_APP_BANCO_EFECTIVO_SN_ANTONIO;
  const BANCO_EFECTIVO_COCO = process.env.REACT_APP_BANCO_EFECTIVO_COCO;
  const BANCO_TPV_MIFEL = process.env.REACT_APP_BANCO_TPV_MIFEL;
  const BANCO_MIFEL = process.env.REACT_APP_BANCO_MIFEL;
  const BANCO_EFECTIVO_PLAZA = process.env.REACT_APP_BANCO_EFECTIVO_PLAZA;

  const BANCO_TPV_TOMATLAN = process.env.REACT_APP_BANCO_TPV_TOMATLAN;
  const BANCO_TPV_SN_ANTONIO = process.env.REACT_APP_BANCO_TPV_SN_ANTONIO;
  const BANCO_TPV_COCO = process.env.REACT_APP_BANCO_TPV_COCO;
  const BANCO_TPV_PLAZA = process.env.REACT_APP_BANCO_TPV_PLAZA;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [text, setText] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedAreaName, setSelectedAreaName] = useState("");
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedClienteNombre, setSelectedClienteNombre] = useState("");
  const [fecha, setFecha] = useState(hoy);
  const [fechaHora, setFechaHora] = useState();
  const [comtdc, setComTDC] = useState(0);
  const [value, setValue] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);
  const [total, setTotal] = useState(0);

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      tipo: "",
      importe: 0,
      banco: "",
    },
  ]);
  const [inputFields2, setInputFields2] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [modalCambiaPrecio, setModalCambiaPrecio] = useState(false);
  const toggleCambiaPrecio = () => setModalCambiaPrecio(!modalCambiaPrecio);
  const [cambiarPrecio, setCambiarPrecio] = useState(false);
  const [password, setPassword] = useState("");

  const [selectedNota, setSelectedNota] = useState("");
  const [fechaNota, setFechaNota] = useState("");
  const [importeNota, setImporteNota] = useState(0);
  const [numeroNota, setNumeroNota] = useState("");
  const [userNota, setUserNota] = useState("");
  const [importeNotaOriginal, setImporteNotaOriginal] = useState(0);

  const [bancos, setBancos] = useState([]);
  const [moneda, setMoneda] = useState("MXN");

  const [articulos, setArticulos] = useState([]);
  const [articulosNota, setArticulosNota] = useState([]);
  const [totalCantidadEdit, setTotalCantidadEdit] = useState();

  useMemo(() => {
    axios
      .get(`${URL_ARTICULOS}Activos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

    if (user.areas) {
      axios
        .get(`${URL_CLIENTES}Area/${user.areas}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allClientes = res.data;
          setClientes(allClientes);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    axios
      .get(URL_BANCOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBancos = response.data;
        setBancos(allBancos);
      })
      .catch((err) => {
        console.log(err);
      });

    if (selectedCliente) {
      axios
        .get(`${URL_VENTAS_TIENDAS}ClienteDiario/${selectedCliente}/${fecha}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allCargos = response.data;
          let arrayTabla = allCargos
            .map((a) => {
              return {
                id: a._id,
                fecha: a.fecha,
                fechaHora: a.fecha + " " + moment(a.createdAt).utcOffset('-0600').format("HH:mm:ss"),
                total_general: a.total_general,
                saldo: a.saldo,
                nota: a.prefijo + a.consecutivo,
                efectivo: 0,
                tdc: 0,
                transfer: 0,
                cortesia: 0,
                totalAbono: 0,
                areas: a.areas[0]._id,
                clientes: a.clientes[0]._id,
                areasName: a.areas[0].name,
                movimiento: "Nota",
                user: a.user[0].nombre + " " + a.user[0].apellido,
                clientesNombre: a.clientes[0].nombre_comercial,
                numero: a.prefijo + a.consecutivo,
                idPDFOC: a._id,
                comisionTDC: 0,
                comisionFlete: a.comisionFlete,
                impuestos: a.impuestos,
                area: a.areas[0].name,
                descuento: a.descuento,
                descuentoNumero:
                  (parseFloat(a.subTotal) * parseFloat(a.descuento)) / 100,
                bancoEfectivo: "",
                bancoTDC: "",
                bancoComisionTDC: "",
                bancoTransfer: "",
              };
            })
            .filter(function (el) {
              return el != null;
            });
          let dataFinal = Object.values(arrayTabla);
          setInputFields2(dataFinal);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedCliente, user]);

  function ActualizaTotales() {
    let TEfe = inputFields.map((c) => parseFloat(c.importe));
    let TE = TEfe.reduce((t, total, index) => t + total, 0);

    setTotal(TE);
  }

  function autorizaCambio() {
    if (password == PASSWORD_CAMBIA_PRECIO) {
      setCambiarPrecio(true);
      toggleCambiaPrecio();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La contraseña es incorrecta!",
      });
    }
  }

  async function PDFOC(
    fecha,
    hora,
    user,
    clientes,
    // numero,
    idPDFOC,
    area,
  ) {
    // await axios
    //   .get(`${URL_ARTICULOS_VENTAS_TIENDAS}/ventasTiendas/${idPDFOC}`, {
    //     headers: {
    //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //     },
    //   })
    //   .then((response) => {
        // let allArticulosVenta = response.data;
        let arrayTabla = articulosNota
          .map((a) => {
            return {
              articulos: a.articulosNombre,
              cantidad: a.cantidad,
              precio: a.precio,
              total: a.total,
              cajas: a.cantidad / a.piezasPorCaja,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let totalKG = dataFinal.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);

        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);

        let cajas = dataFinal.map((c) => parseFloat(c.cajas));
        let TC = cajas.reduce((t, total, index) => t + total, 0);

        let efectivo = inputFields.filter((e) => e.tipo == "Efectivo");
        let TEfe = efectivo.map((c) => parseFloat(c.importe));
        let TE = TEfe.reduce((t, total, index) => t + total, 0);

        let transfer = inputFields.filter((e) => e.tipo == "Transfer");
        let TTran = transfer.map((c) => parseFloat(c.importe));
        let TT = TTran.reduce((t, total, index) => t + total, 0);

        let TDC = inputFields.filter((e) => e.tipo == "TDC");
        let TTdc = TDC.map((c) => parseFloat(c.importe));
        let TTC = TTdc.reduce((t, total, index) => t + total, 0);

        let LA = dataFinal.length * 1.5;
        let largoTotal = LA + 4 + 15;

        let totalParcial = 0;
        let subTotalTabla = 0;

        const data = dataFinal.map((a) => {
          totalParcial = a.cantidad * a.precio;
          subTotalTabla = subTotalTabla + totalParcial;
          return [
            a.articulos,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.precio),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.total),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });

        const doc = new jsPDF("p", "cm", [8, largoTotal]);
        var base64 = qrcode.toDataURL(idPDFOC);
        var img3 = new Image();
        img3.src = base64;

        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";

        doc.addImage(img2, "png", 5, 1, 2, 2);

        doc.setFontSize(6);
        doc.text(`Tandera SA de CV / RFC: TAN090216V4A`, 0.3, 1);
        doc.text(`Mixcalco 32`, 0.3, 1.3);
        doc.text(`Colonia: Centro CDMX`, 0.3, 1.6);
        doc.text(`Delegacion: Cuauhtemoc, CP: 06020`, 0.3, 1.9);
        doc.text(`Fecha: ${fecha} ${hora}`, 0.3, 2.2);
        doc.text(`Nota # ${numeroNota} / Almacen: ${area}`, 0.3, 2.5);
        doc.text(
          `Piezas: ${new Intl.NumberFormat("en-US").format(
            TK
          )} / Cajas ${TC.toFixed(2)}`,
          0.3,
          2.8
        );
        doc.text(`Cliente: ${clientes}`, 0.3, 3.1);
        doc.text(`Vendedor: ${user}`, 0.3, 3.4);
        doc.autoTable({
          head: [["Articulo", "Cant", "Precio", "Total"]],
          body: dataPDFLimpia,
          styles: {
            fontSize: 8,
          },
          startY: 3.8,
          theme: "plain",
          margin: { left: 0.3, right: 0.7 },
          foot: [
            [
              "",
              { content: "SubTotal", colSpan: 2, styles: { halign: "left" } },
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(subTotalTabla),
            ],
           
            [
              "",
              {
                content: "Comision TDC",
                colSpan: 2,
                styles: { halign: "left" },
              },
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(comtdc),
            ],
            [
              "",
              { content: "Total", colSpan: 2, styles: { halign: "left" } },
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(importeNota),
            ],
          ],
          // showFoot: "lastPage",
        });

        doc.autoTable({
          head: [["Efectivo", "TDC", "Transfer", 
          // "Cortesia"
        ]],
          body: [
            [
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TE),
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TTC),
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TT),
            ],
          ],
          styles: {
            fontSize: 8,
        },
          startY: doc.autoTable.previous.finalY + 1,
          showFoot: "lastPage",
          theme:"plain",
          margin:{left:0.3, right:0.7},
        });

        window.open(doc.output("bloburl"), "_blank");
        //doc.save(`Nota-${numero}.pdf`);
      // })
      // .catch((err) => {
      //   console.log(err);
      // });
  }

  const savePago = async () => {
    const hora = moment(date).format("HH:mm:ss");
  
    if (total !== importeNota) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El importe de la nota no coincide con el total!",
      });
      return;
    }
  
    let cantidadIF = inputFields.length;
    for (const a of inputFields) {
      if (!a.banco || !a.tipo) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Seleccione el banco y tipo de pago",
        });
        return;
      }
    }
  
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Se registrará el pago",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, Enviar!",
    }).then(async (result) => {
      if (!result.isConfirmed) return;
  
      setValidaBoton(false);
      toggleProgreso();
  
      try {
        await Promise.all(
          inputFields.map(async (a) => {
            if (a.importe > 0) {
              const abonoData = {
                fecha,
                total: a.importe,
                areas: selectedArea,
                clientes: selectedCliente,
                ventasTiendas: selectedNota,
                efectivo: a.tipo === "Efectivo" ? a.importe : 0,
                tdc: a.tipo === "TDC" ? a.importe : 0,
                transfer: a.tipo === "Transfer" ? a.importe : 0,
                cortesia: 0,
                nomina: 0,
                bancos: a.banco,
              };
  
              const abonoResponse = await axios.post(URL_ABONOS_TIENDAS, abonoData, {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              });
  
              await axios.patch(`${URL_VENTAS_TIENDAS}Abono/${selectedNota}`, {
                total_cantidad: totalCantidadEdit,
                total_general: importeNota,
                saldo: 0,
                subTotal: importeNota - comtdc,
                comisionTDC: comtdc,
                entregado: "Si",
              }, {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              });
  
              await axios.post(`${URL_MOVIMIENTOS_BANCOS}`, {
                fecha,
                importe: a.importe,
                tipo: "Ingreso",
                bancos: a.banco,
                abonosTiendas: abonoResponse.data._id,
                moneda,
                tipoCambio: 1,
              }, {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              });
            }
          })
        );
  
        await Promise.all(
          articulosNota.map((a) =>
            axios.patch(`${URL_ARTICULOS_VENTAS_TIENDAS}AplicarPago/${a.id}`, {
              articulos: a.articulos,
              cantidad: a.cantidad,
              precio: a.precio,
              total: a.total,
              inventarios: a.idInventario,
              entregado: "Si",
            }, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
          )
        );
  
        Swal.fire("Good job!", "Creado con éxito", "success");
        PDFOC(fecha, hora, userNota, selectedClienteNombre, selectedNota, selectedAreaName);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Algo salió mal!",
          footer: error.response?.data || "",
        });
        console.error(error);
        setValidaBoton(true);
      }
    });
  };
  

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial + " " + option.codigo;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function jalaNota(e) {
    setSelectedNota(e);
    inputFields2.map((a) => {
      if (a.id == e) {
        setFechaNota(a.fecha);
        setFechaHora(a.fechaHora);
        setImporteNotaOriginal(a.total_general);
        setImporteNota(a.total_general);
        setSelectedArea(a.areas);
        setSelectedAreaName(a.areasName);
        setNumeroNota(a.nota);
        setUserNota(a.user);
      }
    });
    setTotal(0);


    axios
      .get(`${URL_ARTICULOS_VENTAS_TIENDAS}/ventasTiendas/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosOrden = response.data;
        let arrayTabla = allArticulosOrden
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0]._id,
              articulosNombre: a.articulos[0].nombre +
              " " +
              a.articulos[0].lineas[0].name +
              " " +
              a.articulos[0].familias[0].name +
              " " +
              a.articulos[0].marcas[0].name,
              cantidadAnterior: a.cantidad,
              cantidad: a.cantidad,
              precio: a.precio,
              total: a.total,
              idInventario: a.inventarios[0]._id,
              cantidadInventario: 0,
              piezasPorCaja: a.articulos[0].piezasPorCaja
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        setArticulosNota(dataFinal);

        let cant = dataFinal.map((c) => parseFloat(c.cantidad));
        let TC = cant.reduce((t, total, index) => t + total, 0);
        setTotalCantidadEdit(TC);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleChangeInputExistentes = (id, event) => {
    const newArticulosNota = articulosNota.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setArticulosNota(newArticulosNota);
    ActualizaTotalesArt();
  };

  const handleChangeInputExistentesCantidad = (id, event) => {
    const newArticulosNota = articulosNota.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setArticulosNota(newArticulosNota);
    ActualizaTotalesArt();
  };

  function ActualizaTotalesArt() {
    let cantidad = articulosNota.map((c) => parseFloat(c.cantidad));
    let TC = cantidad.reduce((t, total, index) => t + total, 0);
    setTotalCantidadEdit(TC);

    let importes = articulosNota.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setImporteNota(TG + parseFloat(comtdc));

  }

  const handleChangeInputTipo = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  const handleChangeInputImporte = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
          i.importe = parseFloat(event.target.value);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        tipo: "",
        importe: 0,
        banco: "",
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };

  function jalaComTDC(e) {
    setComTDC(e);

    let importes = articulosNota.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setImporteNota(TG + parseFloat(e));
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.abonosTiendas ? (
        <div className="card container col-sm-11">
          <h3 align="center">Captura un Pago</h3>
          <Form>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Row>
                <Col md={3}>
                  <Label className="mr-sm-2">Cliente</Label>
                  <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedCliente) => {
                      if (selectedCliente) {
                        setValue(selectedCliente);
                        setSelectedCliente(selectedCliente._id);
                        setSelectedClienteNombre(
                          selectedCliente.nombre_comercial
                        );
                      }
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                </Col>

                <Col md={2}>
                  <Label className="mr-sm-2">Notas</Label>
                  <Input
                    type="select"
                    value={selectedNota}
                    onChange={(e) => {
                      jalaNota(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    {inputFields2.map((a) => {
                      return <option value={a.id}>{a.nota}</option>;
                    })}
                  </Input>
                </Col>

                <Col md={7}>
                  <h4 align="right">
                    Total Pago{"  "}
                    <Badge
                      id="Total"
                      color="danger"
                      className="BadgeSize badge-pill"
                    >
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format(total)}
                    </Badge>
                  </h4>
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={3}>
                  <h4>Fecha {fechaNota}</h4>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <h4>
                    Importe{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 2,
                    }).format(importeNota)}
                  </h4>
                </Col>
              </Row>
             
                <>
                  <Row>
                    <Col md={3}></Col>
                    <Col md={2}>
                      <Label className="mr-sm-2">Articulos</Label>
                    </Col>
                    <Col md={1}>
                      <Label className="mr-sm-2">Cantidad</Label>
                    </Col>
                    <Col md={1}>
                      <Label className="mr-sm-2">Precio</Label>
                    </Col>
                    <Col md={2}>
                      <Label className="mr-sm-2">Total</Label>
                    </Col>
                  </Row>

                  {articulosNota.map((ao) => (
                    <div key={ao.id}>
                      <Row>
                        <Col md={3}></Col>
                        <Col md={2}>
                          <Input
                            // bsSize="sm"
                            name="articulos"
                            type="select"
                            value={ao.articulos}
                            required
                            // onChange={(event) => {
                            //   BuscaArticuloExistentes(ao.id, event);
                            // }}
                            disabled
                          >
                            <option value="0">Selecciona</option>
                            {articulos
                              .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                              .map((a) => {
                                return (
                                  <option value={a._id}>{a.nombre}</option>
                                );
                              })}
                          </Input>
                        </Col>

                        <Col md={1}>
                          <Input
                            // bsSize="sm"
                            name="cantidad"
                            type="number"
                            min="0"
                            step="any"
                            placeholder="Cantidad"
                            value={ao.cantidad}
                            required
                            onChange={(event) =>
                              handleChangeInputExistentesCantidad(ao.id, event)
                            }
                          />
                        </Col>

                        <Col md={1}>
                          <Input
                            // bsSize="sm"
                            name="precio"
                            type="number"
                            min="0"
                            step="any"
                            placeholder="Precio"
                            value={ao.precio}
                            required
                            onChange={(event) =>
                              handleChangeInputExistentes(ao.id, event)
                            }
                          />
                        </Col>
                        <Col md={2}>
                          <Input
                            // bsSize="sm"
                            name="total"
                            type="number"
                            min="0"
                            step="any"
                            placeholder="Total"
                            value={ao.total}
                            disabled
                            
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              {/* ) : undefined} */}
              <hr />
            <br />
            <Row>
              <Col md={2}><Label>Comision TDC</Label>
              <Input 
              type="number"
              value={comtdc}
              onChange={(event) => {
                jalaComTDC(event.target.value)
              }}
              />
              </Col>
            </Row>
              <br />
              <br />
              <Row>
                <Col md={2}>
                  <Label>Tipo</Label>
                </Col>
                <Col md={2}>
                  <Label>Importe</Label>
                </Col>
                <Col md={2}>
                  <Label>Banco</Label>
                </Col>
              </Row>
              {inputFields.map((inputField) => (
                <div key={inputField.id}>
                  <Row>
                    <Col md={2}>
                      <Input
                        type="select"
                        name="tipo"
                        value={inputField.tipo}
                        onChange={(event) =>
                          handleChangeInputTipo(inputField.id, event)
                        }
                      >
                        <option value="">Selecciona</option>
                        <option value="Efectivo">Efectivo</option>
                        <option value="TDC">TDC</option>
                        <option value="Transfer">Transfer</option>
                      </Input>
                    </Col>
                    <Col md={2}>
                      <Input
                        name="importe"
                        type="number"
                        min="0"
                        step="any"
                        value={inputField.importe}
                        required
                        onChange={(event) =>
                          handleChangeInputImporte(inputField.id, event)
                        }
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        type="select"
                        name="banco"
                        value={inputField.banco}
                        required
                        onChange={(event) =>
                          handleChangeInputTipo(inputField.id, event)
                        }
                      >
                        <option value="">Selecciona</option>
                        {bancos
                                .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                                .map((a) => {
                                  if (
                                    (user.areas == AREAS_COCO &&
                                      a._id == BANCO_EFECTIVO_COCO) ||
                                    (user.areas == AREAS_TOMATLAN &&
                                      a._id == BANCO_EFECTIVO_TOMATLAN) ||
                                    (user.areas == AREAS_PLAZA &&
                                      a._id == BANCO_EFECTIVO_PLAZA) ||
                                    (user.areas == AREAS_SN_ANTONIO &&
                                      a._id == BANCO_EFECTIVO_SN_ANTONIO) ||
                                    (user.areas == AREAS_COCO &&
                                      a._id == BANCO_TPV_COCO) ||
                                    (user.areas == AREAS_TOMATLAN &&
                                      a._id == BANCO_TPV_TOMATLAN) ||
                                    (user.areas == AREAS_PLAZA &&
                                      a._id == BANCO_TPV_PLAZA) ||
                                    (user.areas == AREAS_SN_ANTONIO &&
                                      a._id == BANCO_TPV_SN_ANTONIO) ||
                                    a._id == BANCO_BANAMEX_SHALOMICO ||
                                    a._id == BANCO_BANAMEX_TANDERA ||
                                    a._id == BANCO_BBVA_COCO_STYLE ||
                                    a._id == BANCO_TPV_MIFEL||
                                    a._id == BANCO_MIFEL
                                  ) {
                                    return (
                                      <option value={a._id}>
                                        {a.banco} {a.cuenta}
                                      </option>
                                    );
                                  }
                                })}
                      </Input>
                    </Col>
                    <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                  </Row>
                </div>
              ))}
              <br />
            </FormGroup>
            <br />
            <Row>
              {validaBoton ? (
                <Button className="btn btn-success" onClick={savePago}>
                  Guardar
                </Button>
              ) : (
                <Button className="btn btn-success" disabled>
                  Guardar
                </Button>
              )}

              <Button
                href="/ListadoAbonosTiendas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Row>
          </Form>
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>

          <Modal
            size="sm"
            isOpen={modalCambiaPrecio}
            toggle={toggleCambiaPrecio}
          >
            <ModalHeader toggle={toggleCambiaPrecio}>
              <h4>Autorizar Cortesia</h4>
            </ModalHeader>
            <ModalBody>
              <Input
                bsSize="sm"
                type="password"
                placeholder="Password"
                value={password}
                required
                onChange={(event) => setPassword(event.target.value)}
              />
              <br />
              <Row>
                <Col>
                  <Button
                    type="submit"
                    className="btn btn-success"
                    onClick={(e) => autorizaCambio()}
                  >
                    Autorizar
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default AbonosTiendasCreate;
